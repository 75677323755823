const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://contxt.ndustrial.io',
    gaTrackingId: null
  },
  header: {
    title: 'Contxt 101',
    githubUrl: 'https://github.com/ndustrialio/contxt-101-app',
    helpUrl: '',
    tweetText: '',
    links: [
      { text: 'Contxt', link: 'https://contxt.ndustrial.io' },
      { text: 'Contxt - Developers', link: 'https://developers.ndustrial.io' }
    ],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY
    }
  },
  sidebar: {
    forcedNavOrder: ['/index', '/1-iot-provisioning', '/2-contxt-app'],
    links: [
      {
        text: 'Contxt Overview',
        link: 'https://contxt.readme.io/docs/contxt-core-overview'
      },
      {
        text: 'Contxt Auth',
        link: 'https://contxt.readme.io/docs/introduction'
      },
      {
        text: 'Contxt IOT',
        link: 'https://contxt.readme.io/docs/introduction-2'
      },
      {
        text: 'Contxt Assets',
        link: 'https://contxt.readme.io/docs/contxt-assets-overview'
      },
      {
        text: 'Contxt Bus',
        link: 'https://contxt.readme.io/docs/contxt-bus-introduction'
      },
      {
        text: 'Contxt Events',
        link: 'https://contxt.readme.io/docs/contxt-events-overview'
      }
    ],
    frontline: false,
    ignoreIndex: false
  },
  siteMetadata: {
    title: 'Contxt 101',
    description: 'An introductory course to the Contxt platform',
    ogImage: null,
    docsLocation:
      'https://github.com/ndustrialio/contxt-101-app/tree/master/content',
    favicon: 'https://contxt.ndustrial.io/favicon.png'
  }
};

module.exports = config;
